.navbar-brand{
    color:var(--main-txtcolor)
}
.ActiveLink{
    background-color:#64FFDA;
}
header{
    background-color:var( --background-color)!important;
    margin-bottom:100px ;
    z-index: 10;
    margin-top: 0;
    position: relative  ;
}
nav{
    font-family: var(--main-font);
}

.nav-item:hover,.nav-link:hover{
color: var(--main-txtcolor);
transition: all 0.5s;
border-radius: 10px;
}
.uil-bars{
    font-size: 2.5rem;
    color:white;
    cursor: pointer;
    transition: all 0.3s;
}
.uil-bars:hover{
    color: var(--main-txtcolor);
}
.nav-link{
    color:var(--main-color);
    font-size: 1rem !important;
    letter-spacing: 1.5px;
}

@media screen and (min-width:992px) {
    nav{
    height: 50px;
    }
         #contact{
            border-left:2px solid #64FFDA;
            margin-left: 70px;
        }
        #contact:hover{
            border-radius: 0;
        }
        .nav-link{
            font-size: 0.8rem;
        }
        .navbar-nav{
            margin-right:50px ;
        }
        .nav-elements{
            position: relative;
          
        }
        .navbar-collapse{
            position: absolute;
            right: 10px;
        }
      
}