@import url('https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@1,300&family=Open+Sans:wght@300&family=Poppins:wght@400;500;600&family=Roboto+Mono:ital,wght@0,100;0,400;1,500&family=Roboto+Slab:wght@500&family=Rubik+Distressed&display=swap');

.big-title{
color: var(--main-titles-color);
}
.About-boxes{
    display: flex;
}

.About-boxes div{
    min-width: 150px;
    padding: 5px;
    border: 2px solid var(--foreign-text-color);
    margin: 5px ;
    height: 70px;
    display: flex;
    align-items: center;
    font-family:Verdana, 'Geneva', Tahoma, sans-serif;
    text-align: center;
    justify-content: center;
    color: var(--main-titles-color);
    line-height: normal;
    border-radius: 10px 2px ;
    font-size: 0.8rem;
    transition: all 0.9s;
    letter-spacing: 1.4px;
    -webkit-user-select: none;
        
    opacity: 0.9;
}
.About-boxes div:hover{
    background-color: var(--main-titles-color);
    color: var(--background-color);
    cursor:auto;
    scale: 1.03;
    opacity: 1;
}


#about-me{
    margin: 50px!important;
}
#my-picture{
    border-top: #64FFDA 3px double;
    border-left: #64FFDA 3px double;
    height: 100%;
}
#content{
    letter-spacing: 3px;
    color: var(--foreign-text-color)!important;
    height: 500px;
    display: flex;
    flex-direction: column;
}
#content>div{
    line-height: 35px;
    height: 100%;
}
#content img{
    height: 100%;
}
#my-picture:hover{
    scale: 1.05;
    border: 2px solid red;
    background-color: #c5f9ed;
    opacity: 0.5;
    /* rotate:180deg */
}

#my-picture{
    display: none;
    transition: all 1.5s;

}


/*  @media screen and (999px<= width ) {
    #about-me{
        margin-right: 100px;
    }
    
 } */
@media screen and (840px<= width <992px) {
   
    .description{
        height: 100% !important;
    }
    #about-me{
        margin-left: 70px!important;
        margin-right: 100px!important;

    }

    
    
}

.main-color{
    color: var(--main-titles-color);
    font-size: 1.1rem;
    font-weight: bold;
}


@media screen and (width >=992px) {
    #about-me{
        margin:  auto!important;
        width: 70%;
    }
    #content{
        display: flex;
        flex-direction: row;
        height: 100%;
        align-items: top;
        margin: 5px;
    align-items: start;

    }
    .description{
        height: 100%;
    }
    #my-picture{
        display: block;
        height: 100%!important;

    }
    #my-picture>img{
max-width: 300px;   
 }


    
}
