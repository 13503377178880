

#form-alert
{

    position: fixed;
    width: 500px;
    height: 500px;
    top: 20%;
    background-color: var(--background-color);
    left: 50%;
    color: var(--main-titles-color);
    border-radius: 10px;
    margin-top: -100px; /* Negative half of height. */
    margin-left: -250px;
    z-index: 100;
    border: 2px solid var(--main-titles-color);

}
#closeIcon{
    margin-left: 90%;
    margin-top: 10px;
    border-radius: 5px;
}

#form-alert>form{
    padding: 30px;
}
@media screen and (500px< width < 900px) {
    
    #form-alert
{

    position: fixed;
    width: 400px;
    height: 500px;
    top: 20%;
    left: 60%;
    margin-top: -100px; /* Negative half of height. */
    margin-left: -250px;
    z-index: 100;
}
#closeIcon{

    margin-left: 85%;
    margin-top: 10px;
    border-radius: 5px;
}
}
@media screen and (max-width: 500px) {
        
    #form-alert
{

    position: fixed;
    width: 350px;
    height: 500px;
    top: 20%;
    left:68%;
    margin-top: -100px; /* Negative half of height. */
    margin-left: -250px;
    z-index: 100;
}
#closeIcon{

    margin-left: 85%;
    margin-top: 10px;
    border-radius: 5px;
}
}
