.App {
  text-align: center;
}

:root{
  --background-color : rgb(0, 18, 37);
  --header-background-color : rgb(0, 11, 23);
  --main-txtcolor : #64FFDA;
  --main-color : rgb(249, 249, 249);   
  --main-titles-color : #64FFDA;
  --main-txt-color : white;
  --foreign-text-color:rgb(182, 182, 182);
  --main-font:'SF Mono','Fira Code','Fira Mono','Roboto Mono',monospace;
}
@import url('https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@1,300&family=Open+Sans:wght@300&family=Poppins:wght@400;500;600&family=Roboto+Mono:ital,wght@0,100;0,400;1,500&family=Roboto+Slab:wght@500&family=Rubik+Distressed&display=swap');
body{
    background-color: var(--background-color)!important;
    overflow-x: hidden;

}
*::selection{
  background-color: #64FFDA;
      color: black;
}
.custom-btn{
  width: 80px;
  border-radius: 5px;
  padding:5px;
  border: 1px solid var(--main-titles-color);
  background-color: inherit;
  color: var(--main-txtcolor);
  font-size: 0.9rem;
}
.custom-btn:hover{
  background-color: var(--main-titles-color);
  color: black;
}
*{
    font-family: var(--main-font) ;
    font-size: 1.1rem;
    
    
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@media screen and (min-width:575.3px) {
body{
  position: relative;
}
}
.important-words{
  border-bottom: 4px rgb(223, 66, 255) ridge;
  font-weight: bold;
}