#my-skills{
    margin:50px;
}
.big-title{
    color: var(--main-titles-color);
    }
.skills-content{
    width: 90%;
    min-height: 250px;
    padding: 25px;
    border: 3px var(--main-txtcolor) solid;
    margin:auto;
    margin-bottom: 5px;
    border-radius: 15px;
    transition: 2s all;
}

@media screen and (width<766px) {
    #my-skills{
        margin-top: 150px;
        margin-left: 50px;
}
}
@media screen and (width<666px) {
    #my-skills{
        margin-top: 250px;
        margin-left: 50px;
}
@media screen and (width<536px) {
    #my-skills{
        margin-top: 350px;
        margin-left: 50px;
   }
}
}
@media screen and (width >= 992px) {
    #my-skills{
        margin-left: 130px!important;
         margin:  auto!important; 
        width: 70%;
    }
    #all-skills{
        display: flex;
        flex-wrap: wrap;
    }
    .skills-content{
        width: 48%;
        margin: 1px;
        margin-bottom: 5px;
    }

}
@media screen and (840px<= width <992px) {
    #my-skills{
        margin-left: 70px!important;
        margin-right: 100px!important;

    }
    #all-skills{
        display: flex;
        flex-wrap: wrap;
    }
}