
#div-email{
    display: none;

}
#mailto{
    text-decoration: none;
    color: var(--main-color);
}
#mailto:hover{
    color: var(--main-txtcolor);
}
#social-media{
    display: none;
}

@media screen and (min-width:992px) {

#div-email{
    color:var(--main-color);
    font-size:1.6rem;
    position: fixed;
    right: 0;
    margin-bottom: 100px;
    rotate: 90deg;
    display: flex;
    align-items: center;
    bottom: 0;
    margin-bottom: 130px;
    z-index: 3;
    cursor: pointer;
    font-weight: bold;

}
#div-email:hover{
    color:var(--main-txtcolor)
    
}
#social-media{
    position: fixed;
    display: flex;
    align-items: center;
    color: aliceblue;
    font-weight: bold;
    bottom: 0;
    left: -50;
    margin-bottom: 100px;
    rotate: 90deg;
}
#social-media a{
    margin: 5px;
    transition: all 0.3s;
}
#social-media img{
    width: 25px;
    rotate: -90deg;
    transition:all 0.3s;
}
#social-media a:hover{
    scale: 1.5;
    opacity: 0.8;
    rotate: 10deg ;
}

}
