#projects{
    margin:150px;
}
video {
    max-width: 400px;
}

.item{
    display: flex;
    margin-bottom: 05px;

}
.item> div{
    padding: 5px;
    margin: 10px;
}
.item li,p {
    font-size:0.85rem;
}

@media screen and (width<1120px) {
    .item{
        display: flex;
        flex-direction: column;
        align-items: center;
        align-items: start;
        margin-bottom: 55px;
    }
         .content {
             height: 100% !important;
         }
    
         #projects {
             margin-left: 70px !important;
             margin-right: 100px !important;
    
         }
  
 
}

