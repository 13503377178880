#-title{
    color:var(--main-txtcolor);
    letter-spacing: 2px;
}
#name{
    font-size: 1.9rem;
    margin: 20px;
    color: var(--foreign-text-color);
}
#welcome-section{
    margin: 50px 0px 50px 0px!important;

}
#welcome-section{
    margin: 150px 50px!important;
    
}
#opt-dev{
    color: var(--main-txtcolor);
}
#download-resume{
    text-decoration: none;
}
.text{
    letter-spacing: 3px;
    color: var(--foreign-text-color);
    line-height: 30px;
}
@media screen and (width >=992px) {
    #welcome-section{
        width: 70%;
        margin: 50px auto !important;
        
    }
    #name{
        font-size: 3rem;
    }

}